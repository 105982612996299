import Vue from "vue";
import UserShort from "@/api/models/user/UserShort";
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";
import User from "@/api/models/user/User";
import PaymentType from "@/enums/PaymentType";
import {DataFormatter, DateFormatter} from "@/api/helpers";

class UserDataHelper extends Vue {
    saveInitialUserData(resp) {
        let {
            // hasTrial,
            token,
            userDetails,
            refreshToken,
            unreadUserNotificationsCount,
            userAgreementHasNotExpired,
            userRegularSubscriptionHasExpired,
            userTrialSubscriptionHasExpired,
        } = resp;
        var UserData = null;
        if (userDetails) {
            let detailsModel = JSON.parse(userDetails);
            UserData = this.replaceNullStrings(new User(detailsModel));
            if (
                detailsModel.subscriptionPrivilegesGroups &&
                detailsModel.subscriptionPrivilegesGroups.length > 0
            )
                UserData.subscriptionPeriod =
                    detailsModel.subscriptionPrivilegesGroups[0].paymentPeriod ===
                    "annually"
                        ? SubscriptionPeriod.annually
                        : SubscriptionPeriod[
                            detailsModel.subscriptionPrivilegesGroups[0].paymentPeriod
                            ];
        }
        if (UserData) {
            UserData.unreadNotificationsCount = unreadUserNotificationsCount;
            UserData.userRegularSubscriptionHasExpired =
                userRegularSubscriptionHasExpired == "true";
            UserData.userTrialSubscriptionHasExpired =
                userTrialSubscriptionHasExpired == "true";
        }
        return UserData;
    }

    createUserData(userDetails) {
        var UserData = null;
        if (userDetails) {
            let detailsModel = userDetails;
            UserData = this.replaceNullStrings(new User(detailsModel));
            if (
                detailsModel.subscriptionPrivilegesGroups &&
                detailsModel.subscriptionPrivilegesGroups.length > 0
            )
                UserData.subscriptionPeriod =
                    detailsModel.subscriptionPrivilegesGroups[0].paymentPeriod ===
                    "annually"
                        ? SubscriptionPeriod.annually
                        : SubscriptionPeriod[
                            detailsModel.subscriptionPrivilegesGroups[0].paymentPeriod
                            ];
        }
        return UserData;
    }

    replaceNullStrings(UserData) {
        if (!UserData) return UserData;
        if (UserData.alternateEmail === "null") {
            UserData.alternateEmail = null;
        }
        if (UserData.zipCode === "null") {
            UserData.zipCode = null;
        }
        if (UserData.streetAddress === "null") {
            UserData.streetAddress = null;
        }
        if (UserData.phoneNumber === "null") {
            UserData.phoneNumber = null;
        }
        if (UserData.userLogo === "null") {
            UserData.userLogo = null;
        }
        if (UserData.marketplaceMenuLabel === "null") {
            UserData.marketplaceMenuLabel = null;
        }
        return UserData;
    }

    isExpired(UserData) {
        return (
            UserData.userTrialSubscriptionHasExpired ||
            UserData.userRegularSubscriptionHasExpired
        );
    }

    updateUserData(resp, UserData) {
        if (!resp) return;
        let userShort = new UserShort(resp);
        if (!userShort) return;
        if (!UserData) UserData = this.getUser();
        UserData.unreadNotificationsCount = userShort.unreadNotificationsCount;
        UserData.subscriptionName = userShort.subscriptionName;
        UserData.subscriptionDisplayName = userShort.subscriptionDisplayName;
        UserData.privilageCode = userShort.privilegeCodeList;
        UserData.privilageTag = userShort.privilegeTagList;
        UserData.subscriptionTrialExpDate = userShort.subscriptionTrialExpDate;
        UserData.userGroup = userShort.userGroup;
        if (userShort.brandingLogo === "null") {
            userShort.brandingLogo = null;
        }
        UserData.userLogo = userShort.brandingLogo;

        //UserData.subscriptionCancellationDate =
        //  userShort.subscriptionCancellationDate;
        UserData.subscriptionIsTrial = userShort.subscriptionIsTrial;
        UserData.subscriptionPeriod =
            userShort.subscriptionPeriod === "annually"
                ? SubscriptionPeriod.annually
                : SubscriptionPeriod[userShort.subscriptionPeriod];
        UserData.displayName = userShort.userDisplayName;
        UserData.userRegularSubscriptionHasExpired = userShort.userRegularSubscriptionHasExpired; // check not only the flag but also the date subscriptionCancellationDate!=null
        UserData.userTrialSubscriptionHasExpired = userShort.userTrialSubscriptionHasExpired;
        return UserData;
    }

    isPrivilegeExists(user, privilegeName) {
        console.log("user:");
        console.log(user);
        console.log("privilegeName = " + privilegeName);
        if (user && user.privilageCode && user.privilageCode.includes(privilegeName)) {
            return true;
        }
        if (user && user.privilageTag && user.privilageTag.includes(privilegeName)) {
            return true;
        }
        return false;
    }

    orderBy(obj, column, type) {
        let orderBy = type.toUpperCase();
        if (["ASC", "DESC"].indexOf(orderBy) == -1) {
            console.error($type, "Not valid, please use ASC or DESC");
            return;
        }
        this["order" + orderBy](obj, column);
    }

    orderASC(obj, column) {
        obj.sort((a, b) => {
            return a[column] > b[column] ? 1 : b[column] > a[column] ? -1 : 0;
        });
    }

    orderDESC(obj, column) {
        obj.sort((a, b) => {
            return a[column] > b[column] ? -1 : b[column] > a[column] ? 1 : 0;
        });
    }

    getPMCardText(paymentMethod, paymentMethodDisplay, paypalEmail) {
        if (
            paymentMethod &&
            paymentMethod === "Paypal" &&
            paypalEmail &&
            paypalEmail !== "null"
        ) {
            return paymentMethodDisplay + " (" + paypalEmail + ")";
        } else {
            return paymentMethodDisplay;
        }
    }

    cardName(
        paymentMethod,
        paymentMethodDisplay,
        cardType,
        cardLast4,
        withName = true
    ) {
        if (paymentMethod && (!cardLast4 || cardLast4 === "null")) {
            return this.getPMCardText(paymentMethod, paymentMethodDisplay, null);
        } else if (
            paymentMethod &&
            paymentMethod === "Stripe" &&
            cardLast4 &&
            cardLast4 !== "null"
        ) {
            return (
                (withName ? DataFormatter.creditCardTypeName(cardType) : "") +
                "\u0020\u2022\u2022\u2022\u2022" +
                cardLast4
            );
        }
        return paymentMethodDisplay;
    }

    //toBack
    preparePaymentsArray(resp) {
        var res = [];
        let {subscriptionPayments, addonsPayments, ordersPayments} = resp;
        if (subscriptionPayments) {
            subscriptionPayments.forEach((item) => {
                let slot = {
                    id: item.paymentHistoryId,
                    type: PaymentType.subscription,
                    originalAmount: item.amount,
                    amount: Math.abs(item.amount),
                    payment_date: DateFormatter.strToUTC(item.paymentDate),
                    description: item.subscriptionDisplayName,
                    paymentMethodClass: DataFormatter.creditCardTypeImage(item.cardType),
                    paymentMethodString: this.cardName(
                        item.paymentMethod,
                        item.paymentMethodDisplay,
                        item.cardType,
                        item.cardLast4,
                        true
                    ),
                    paymentMethod: this.cardName(
                        item.paymentMethod,
                        item.paymentMethodDisplay,
                        item.cardType,
                        item.cardLast4,
                        false
                    ),
                    comments: item.comments,
                };
                res.push(slot);
            });
        }
        if (addonsPayments) {
            addonsPayments.forEach((item) => {
                res.push({
                    id: item.paymentHistoryId,
                    type: PaymentType.addon,
                    originalAmount: item.amount,
                    amount: Math.abs(item.amount),
                    payment_date: DateFormatter.strToUTC(item.paymentDate),
                    description: item.paymentEntityKeyValue,
                    paymentMethodClass: DataFormatter.creditCardTypeImage(item.cardType),
                    paymentMethodString: this.cardName(
                        item.paymentMethod,
                        item.paymentMethodDisplay,
                        item.cardType,
                        item.cardLast4,
                        true
                    ),
                    paymentMethod: this.cardName(
                        item.paymentMethod,
                        item.paymentMethodDisplay,
                        item.cardType,
                        item.cardLast4,
                        false
                    ),
                    comments: item.comments,
                });
            });
        }
        if (ordersPayments) {
            ordersPayments.forEach((item) => {
                try {
                    res.push({
                        id: item.paymentHistoryId,
                        type: PaymentType.order,
                        originalAmount: item.amount,
                        amount: Math.abs(item.amount),
                        payment_date: DateFormatter.strToUTC(item.paymentDate),
                        description: "Order #" + item.paymentEntityKeyValue,
                        paymentMethodClass: DataFormatter.creditCardTypeImage(
                            item.cardType
                        ),
                        paymentMethodString: this.cardName(
                            item.paymentMethod,
                            item.paymentMethodDisplay,
                            item.cardType,
                            item.cardLast4,
                            true
                        ),
                        paymentMethod: this.cardName(
                            item.paymentMethod,
                            item.paymentMethodDisplay,
                            item.cardType,
                            item.cardLast4,
                            false
                        ),
                        comments: item.comments,
                    });
                } catch (ex) {
                    console.error(ex);
                }
            });
        }
        return res;
    }

    getUser() {
        var user = null;
        let userStr = sessionStorage.getItem("user");
        if (userStr) {
            try {
                user = new User(JSON.parse(userStr));
            } catch (ex) {
                user = null;
            }
        }
        return user;
    }

    getUserId() {
        var userId = -1;
        let userStr = sessionStorage.getItem("user");
        var user = null;
        if (userStr) {
            try {
                user = new User(JSON.parse(userStr));
                userId = user.userId;
            } catch (ex) {
                userId = -1;
            }
        }
        return userId;
    }

    getPrivilegeValue(privilegeCode) {
        var privilege_value = 0;
        var user = this.getUser();
        if (user != null) {
            // user subscription privileges
            if (user.subscriptionPrivileges) {
                for (var i = 0; i < user.subscriptionPrivileges.length; i++) {
                    var subs_privilege = user.subscriptionPrivileges[i];
                    if (subs_privilege.privilegesCode == privilegeCode) {
                        if (subs_privilege.privilegeValue != null) {
                            privilege_value += subs_privilege.privilegeValue;
                        } else {
                            privilege_value = null;
                            break;
                        }
                    }
                }
            }
            // user add-ons
            if (user.userAddOnsList != null) {
                for (var i = 0; i < user.userAddOnsList.length; i++) {
                    var addOn = user.userAddOnsList[i];
                    if (addOn.privilegeChildObjects) {
                        for (var j = 0; j < addOn.privilegeChildObjects.length; j++) {
                            var childPrivilege = addOn.privilegeChildObjects[j];
                            if (childPrivilege.privilegeCode == privilegeCode) {
                                if (childPrivilege.privilegeValue != null) {
                                    privilege_value += childPrivilege.privilegeValue;
                                } else {
                                    privilege_value = null;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return privilege_value;
    }

    checkPrivilegeCode(privilegeCode) {
        var user = this.getUser();
        if (user != null) {
            return (
                (user.privilageCode != null &&
                    user.privilageCode.includes(privilegeCode)) ||
                (user.privilageTag != null && user.privilageTag.includes(privilegeCode))
            );
        }
        return false;
    }
}

export default new UserDataHelper();
