import {ROUTER_LINKS} from "@/plugins/Constants";
import Index from "@/views/Index.vue";
import MenuHeader from "@/layout/MenuHeader.vue";
import MainFooter from "@/layout/MainFooter.vue";
import Calculator from "@/views/Calculator.vue";
import ProfileSidebar from "@/layout/ProfileSidebar.vue";

const legacyRoutes = [
    {
        path: ROUTER_LINKS.index.path,
        name: ROUTER_LINKS.index.name,
        components: {
            default: Index,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: false,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.login.path,
        name: ROUTER_LINKS.login.name,
        components: {
            default: () => import("@/views/Login.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: true,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.register.path,
        name: ROUTER_LINKS.register.name,
        components: {
            default: () => import("@/views/Register.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: true,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.change_password.path,
        name: ROUTER_LINKS.change_password.name,
        components: {
            default: () => import("@/views/ChangePassword.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: true,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.verify.path,
        name: ROUTER_LINKS.verify.name,
        components: {
            default: () => import("@/views/UserVerify.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: false,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.send_email.path,
        name: ROUTER_LINKS.send_email.name,
        components: {
            default: () => import("@/views/SendEmailForUser.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            hideForAuth: false,
            requiresAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.payment_finished.path,
        name: ROUTER_LINKS.payment_finished.name,
        components: {
            default: () => import("@/views/PaymentFinished.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.profile_personal_info.path,
        name: ROUTER_LINKS.profile_personal_info.name,
        components: {
            default: () => import("@/views/settings/Profile.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.trial_expired.path,
        name: ROUTER_LINKS.trial_expired.name,
        components: {
            default: () => import("@/views/TrialExpired.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.business.path,
        name: ROUTER_LINKS.business.name,
        components: {
            default: () => import("@/views/PlansProfessional.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.personal.path,
        name: ROUTER_LINKS.personal.name,
        components: {
            default: () => import("@/views/PlansPersonal.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.about.path,
        name: ROUTER_LINKS.about.name,
        components: {
            default: () => import("@/views/AboutUs.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.feedback.path,
        name: ROUTER_LINKS.feedback.name,
        components: {
            default: () => import("@/views/Feedback.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.contact.path,
        name: ROUTER_LINKS.contact.name,
        components: {
            default: () => import("@/views/ContactUs.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.developers.path,
        name: ROUTER_LINKS.developers.name,
        components: {
            default: () => import("@/views/Developers.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.announcements.path,
        name: ROUTER_LINKS.announcements.name,
        components: {
            default: () => import("@/views/Announcements.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.terms.path,
        name: ROUTER_LINKS.terms.name,
        components: {
            default: () => import("@/views/Terms.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.calculator.path,
        name: ROUTER_LINKS.calculator.name,
        components: {
            default: Calculator,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.cookies.path,
        name: ROUTER_LINKS.cookies.name,
        components: {
            default: () => import("@/views/ManageCookies.vue"),
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.payment.path,
        name: ROUTER_LINKS.payment.name,
        components: {
            default: () => import("@/views/Payment.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.redeemingReportForApi.path,
        name: ROUTER_LINKS.redeemingReportForApi.name,
        components: {
            default: () => import("@/views/RedeemingReport.vue"),
        },

        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.summaryReportForApi.path,
        name: ROUTER_LINKS.summaryReportForApi.name,
        components: {
            default: () => import("@/views/SummaryReport.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },

        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.profile.path,
        name: ROUTER_LINKS.profile.name,
        components: {
            default: () => import("@/views/settings/Profile.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.security.path,
        name: ROUTER_LINKS.security.name,
        components: {
            default: () => import("@/views/settings/Security.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.companies.path,
        name: ROUTER_LINKS.companies.name,
        components: {
            default: () => import("@/views/settings/Companies.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.your_plan.path,
        name: ROUTER_LINKS.your_plan.name,
        components: {
            default: () => import("@/views/settings/YourPlan.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.profile_api.path,
        name: ROUTER_LINKS.profile_api.name,
        components: {
            default: () => import("@/views/settings/APIScreenProfile.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.payments.path,
        name: ROUTER_LINKS.payments.name,
        components: {
            default: () => import("@/views/settings/Payments.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.manageUserGroups.path,
        name: ROUTER_LINKS.manageUserGroups.name,
        components: {
            default: () => import("@/views/settings/ManageUserGroups.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.investorProfile.path,
        name: ROUTER_LINKS.investorProfile.name,
        components: {
            default: () => import("@/views/settings/InvestorProfile.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.investorProfileCustom.path,
        name: ROUTER_LINKS.investorProfileCustom.name,
        components: {
            default: () => import("@/views/settings/InvestorProfileCustom.vue"),
            sidebar: ProfileSidebar,
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.forSale.path,
        name: ROUTER_LINKS.forSale.name,
        components: {
            default: () => import("@/views/ForSale.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.not_found.path,
        components: {
            default: () => import("@/views/NotFound.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.vendorContact.path,
        name: ROUTER_LINKS.vendorContact.name,
        components: {
            default: () => import("@/views/VendorContactUs.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    },
    {
        path: ROUTER_LINKS.editFilter.path,
        name: ROUTER_LINKS.editFilter.name,
        components: {
            default: () => import("@/views/EditFilter.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
    {
        path: "/*",
        redirect: ROUTER_LINKS.not_found.path,
        meta: {
            requiresAuth: false,
            hideForAuth: false,
        },
    }
]

export default legacyRoutes;