import Vue from "vue";

class DataFormatter extends Vue {
  currency(numberVal, decimals) {
    if (typeof numberVal !== "number") {
      return numberVal;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
    });
    return formatter.format(numberVal);
  }
  currencyStringToNumber(stringVal) {
    if (typeof stringVal == "string") {
      let numberVal = Number(stringVal);
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(numberVal);
    }
  }
  
  currencyThreeDigits(numberVal) {
    if (typeof numberVal !== "number") {
      return numberVal;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
    return formatter.format(numberVal);
  }
  creditCardTypeName(cType) {
    switch (cType) {
      case "amex":
        return "American Express";
      case "cartes_bancaires":
        return "Cartes Bancaires";
      case "diners_club":
        return "Diners Club";
      case "discover":
        return "Discover";
      case "jcb":
        return "JCB";
      case "mastercard":
        return "Mastercard";
      case "visa":
        return "Visa";
      case "unionpay":
        return "Unionpay";
      default:
        return "";
    }
  }
  creditCardTypeImage(cType) {
    if (!cType || cType === "null") {
      return "hide";
    }
    switch (cType) {
      case "amex":
        return "AM_EX ml-5 loadable-icon-and-text-icon";
      case "cartes_bancaires":
        return "VISA ml-5 loadable-icon-and-text-icon";
      case "diners_club":
        return "VISA ml-5 loadable-icon-and-text-icon";
      case "discover":
        return "DISCOVER ml-5 loadable-icon-and-text-icon";
      case "jcb":
        return "VISA ml-5 loadable-icon-and-text-icon";
      case "mastercard":
        return "MASTERCARD ml-5 loadable-icon-and-text-icon";
      case "visa":
        return "VISA ml-5 loadable-icon-and-text-icon";
      case "unionpay":
        return "VISA ml-5 loadable-icon-and-text-icon";
      default:
        return "hide";
    }
  }

  generateCreditCardString(cType) {}
}

export default new DataFormatter();
