import { DateFormatter, DataFormatter } from "@/api/helpers";
import Subscription from "@/enums/Subscription";
import { REDIRECT_LINKS, NULL_PHOTO } from "@/plugins/Constants";

const ALERT_DANGER_COLOR = "#f55145",
  INFOCOLOR = "#00cae3",
  SuccessColor = "#4caf50",
  error = "error",
  success = "success",
  info = "info";

const HeaderImage = {
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
};

const VerticalNav = {
  methods: {
    scrollToElement(elem) {
      let element_id = document.getElementById(elem);
      if (element_id) {
        element_id.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },
    scrollListener() {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener, { passive: true });
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
};

const Resize = {
  methods: {
    scrollToElement(elem) {
      let element_id = document.getElementById(elem);
      if (element_id) {
        element_id.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },
    scrollListener() {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener, { passive: true });
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
};

const ShowToastr = {
  methods: {
    showError(name, msg) {
      this.showToast(name + "-" + error, msg, error);
    },
    showSuccess(name, msg) {
      this.showToast(name + "-" + success, msg, success);
    },
    showInfo(name, msg) {
      this.showToast(name + "-" + info, msg, info);
    },
    showToast(name, msg, type) {
      let color = (type) => {
        let bc;
        switch (type) {
          case success:
            bc = SuccessColor;
            break;
          case info:
            bc = INFOCOLOR;
            break;
          case error:
            bc = ALERT_DANGER_COLOR;
            break;
          default:
            bc = INFOCOLOR;
        }
        return bc;
      };
      this.$toastr.Add({
        name: name, // this is give you ability to use removeByName method
        msg: msg, // Toast Message
        clickClose: false, // Click Close Disable
        timeout: 5000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
        classNames: ["fw-500"],
        position:
          window.innerWidth > 768 ? "toast-top-right" : "toast-top-full-width", // Toast Position.
        type: type, // Toast type,
        preventDuplicates: true,
        progressbar: false,
        style: { backgroundColor: color, opacity: 1 },
      });
    },
  },
};

const DetectForSaleFilters = {
  data() {
    var isNoteType = this.calcNoteTypeWidth();
    var isNotePosition = this.calcNotePositionWidth();
    return {
      isNoteType,
      isNotePosition,
    };
  },
  watch: {
    "$screen.width"() {
      this.isNoteType = this.calcNoteTypeWidth();
      this.isNotePosition = this.calcNotePositionWidth();
    },
  },
  methods: {
    calcNoteTypeWidth() {
      return this.$screen.width > 830;
    },
    calcNotePositionWidth() {
      return this.$screen.width > 1040;
    },
  },
};

const DetectSmallTabletPortrait = {
  data() {
    var isSmallTabletPortrait = this.calcSmallTabletWidth();
    return {
      isSmallTabletPortrait,
    };
  },
  watch: {
    "$screen.width"() {
      this.isSmallTabletPortrait = this.calcSmallTabletWidth();
    },
  },
  methods: {
    calcSmallTabletWidth() {
      return this.$screen.width < 601;
    },
  },
};

const DetectMobile = {
  data() {
    var isMobile = this.calcMobileWidth();
    return {
      isMobile,
    };
  },
  watch: {
    "$screen.width"() {
      this.isMobile = this.calcMobileWidth();
      //this.setContentMargin();
    },
  },
  methods: {
    calcMobileWidth() {
      return this.$screen.width <= 991;
    },
  },
};

const DetectXSmallTabletPortrait = {
  data() {
    var isXSmallTabletPortrait = this.calcXSmallTabletWidth();
    return {
      isXSmallTabletPortrait,
    };
  },
  watch: {
    "$screen.width"() {
      this.isXSmallTabletPortrait = this.calcXSmallTabletWidth();
    },
  },
  methods: {
    calcXSmallTabletWidth() {
      return this.$screen.width <= 500;
    },
  },
};

const DisablePageOverflow = {
  methods: {
    disableScroll() {
      const container = document.body;
      const overflowModalClass = ["overflow-y-hidden"];
      if (container.className) overflowModalClass.push(container.className);
      if (container && !container.classList.contains(overflowModalClass[0])) {
        document.body.classList.add(...overflowModalClass);
      }
    },
    enableScroll() {
      let container = document.body;
      if (container) {
        var overflowModalClass = "overflow-y-hidden";
        if (container.classList.contains(overflowModalClass)) {
          document.body.classList.remove(overflowModalClass);
        }
      }
    },
    enableScrollMap() {
      let container = document.body;
      let map = document.getElementById("map");
      if (this.$route.name == "for-sale") {
        if (container && map && map.classList.contains("invisible")) {
          var overflowModalClass = "overflow-y-hidden";
          if (container.classList.contains(overflowModalClass)) {
            document.body.classList.remove(overflowModalClass);
          }
        }
      } else {
        if (container) {
          var overflowModalClass = "overflow-y-hidden";
          if (container.classList.contains(overflowModalClass)) {
            document.body.classList.remove(overflowModalClass);
          }
        }
      }
    },
  },
};

const SetPath = {
  methods: {
    setPath(token) {
      var redirectUrl = null;
      if (token) {
        this.hasToken = true;
        redirectUrl =
          process.env.VUE_APP_API_URL + "/#!/rout/" + encodeURIComponent(token);
      } else {
        this.hasToken = false;
        this.subscription = Subscription.plans;
        this.subscribePath =
          process.env.VUE_APP_API_URL + "/#!" + REDIRECT_LINKS.subscribe;
        redirectUrl = null;
      }
      return redirectUrl;
    },
  },
};

const Loading = {
  methods: {
    showLoading() {
      this.LoadingStore.isLoading = true;
    },
    hideLoading() {
      this.LoadingStore.isLoading = false;
    },
  },
};

const DateFormattedM = {
  methods: {
    dateFormatted(numericDate) {
      return DateFormatter.UTCToLongString(numericDate);
    },
    dateFormattedUTC(numericDate) {
      return DateFormatter.UTCToLongTimeString(numericDate * 1000);
    },
    dateFormattedAmPm(numericDate) {
      return DateFormatter.UTCToAmPmTimeString(numericDate * 1000);
    },
  },
};

const ForSaleLinkHelper = {
  computed: {
    showCompany() {
      return (item) =>
        item.companyOwnership !== null && item.companyOwnership != ""
          ? true
          : false;
    },
  },
  methods: {
    checkImage(img) {
      return img && img !== NULL_PHOTO;
    },
    setPath(token) {
      var redirectUrl = null;
      if (token) {
        this.hasToken = true;
        redirectUrl =
          process.env.VUE_APP_API_URL + "/#!/rout/" + encodeURIComponent(token);
      } else {
        this.hasToken = false;
        this.subscription = Subscription.plans;
        this.subscribePath =
          process.env.VUE_APP_API_URL + "/#!" + REDIRECT_LINKS.subscribe;
        redirectUrl = null;
      }
      return redirectUrl;
    },
    redirectToNoteDetailsPath(item, tabId, previousState) {
      let noteDetailsPath = this.getNoteDetailsPath(item, tabId, previousState);
      if (!noteDetailsPath) {
        this.$router.push(ROUTER_LINKS.login.path);
        return;
      }
      window.location.href = noteDetailsPath;
    },
    getNoteDetailsPath(item, tabId, previousState) {
      let token = sessionStorage.getItem("token");
      if (!token) {
        this.$router.push(ROUTER_LINKS.login.path);
        return "";
      }
      let redirectUrl = this.setPath(token);
      var noteDetailsPath = redirectUrl + REDIRECT_LINKS.noteDetail + item.noteCode + "&tabId=" + tabId + "&previousState=" + previousState;
      return noteDetailsPath;
    },
    formatString(val) {
      return DataFormatter.currencyStringToNumber(val);
    },
    getDashboardLinkName(item) {
      const formatL = (val) => {
        return DataFormatter.currency(val);
      };
      const formatM = (val) => {
        return DataFormatter.currencyStringToNumber(val);
      };
      if (item.marketValue != null && item.marketValue.data != null) {
        return `${item.noteTypeName} ${item.notePosition} - ${formatL(
          item.unpaidBalance
        )} UPB @ ${item.interestRateInitial}% - ${formatM(
          item.marketValue.data
        )} market value in ${item.city}, ${item.state}`;
      }
      return `${item.noteTypeName} ${item.notePosition} - ${formatL(
        item.unpaidBalance
      )} UPB @ ${item.interestRateInitial}% - in ${item.city}, ${item.state}`;
    },

    format(val) {
      return DataFormatter.currency(val);
    },
  },
};

const ForSaleDataHelper = {
  methods: {
    filters() {
      return [
        {
          label: this.$t("sort-by.itb"),
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.itb",
          mobileOnly: false,
        },
        {
          label: "ITV",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.currentITV",
          mobileOnly: false,
        },
        {
          label: "LTV",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.currentLTV",
          mobileOnly: false,
        },
        {
          label: "YIELD",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.yield",
          mobileOnly: false,
        },
        {
          label: "RATE",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.interestRateInitial",
          mobileOnly: false,
        },
        // {
        //   label: "PRICE",
        //   model: {
        //     from: null,
        //     to: null,
        //   },
        //   type: "input-range",
        //   name: "n.notePrice",
        //   mobileOnly:true,
        // },
        {
          label: "MARKET VALUE",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.marketValue",
          mobileOnly: false,
        },
        {
          label: "NOTE TYPE",
          model: [],
          type: "checkbox",
          name: "n.noteTypeName",
          mobileOnly: true,
        },
        {
          label: "NOTE POSITION",
          model: [],
          type: "checkbox",
          name: "n.notePosition",
          mobileOnly: true,
        },
        {
          label: "UNPAID BALANCE",
          model: {
            from: null,
            to: null,
          },
          type: "input-range",
          name: "n.unpaidBalance",
          mobileOnly: false,
        },
        {
          label: "COMPANY",
          model: null,
          type: "input",
          name: "n.companyOwnership",
          mobileOnly: false,
        },
      ];
    },
  },
};

const Mixins = {
  VerticalNav,
  HeaderImage,
  ShowToastr,
  SetPath,
  Loading,
  DetectForSaleFilters,
  DetectMobile,
  DetectSmallTabletPortrait,
  DetectXSmallTabletPortrait,
  DisablePageOverflow,
  DateFormattedM,
  ForSaleLinkHelper,
  ForSaleDataHelper,
};

export default Mixins;
